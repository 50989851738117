<style>
.analysis-table button{min-width: 120px;font-size: 12px;position: relative;}
.analysis-table button .arrow{position: absolute;top: -3px;font-size: 18px!important;right: 4px;}
.v-application--is-ltr .v-input__prepend-outer {margin-right: 0px !important;margin-left: 8px !important;} 
</style>
<template>
<div>
<v-row class="ma-0">
    <v-col class="pa-2">
          <v-card class="">
            <div class="pa-1 d-flex justify-space-around">
            <v-row class="ma-0 align-center" width="fit-content">
              <v-col cols="2" class="pa-0">
              <v-autocomplete label="Instrument" height="40" v-model="instrument" :items="symbolsList"
              class="search-area subtitle-2 d-flex align-center" hide-no-data hide-details solo flat dense
              prepend-icon="mdi-magnify" placeholder="Search...ex:Bank Nifty"></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pa-0"><v-autocomplete height="40" v-model="expirydate" :items="expiryList"
                class="search-area subtitle-2 pt-0 pl-4 mt-0" hide-no-data hide-details solo flat dense
                placeholder="Expiry Date"></v-autocomplete></v-col>
             <v-col class="d-flex justify-space-between">
                <!-- <span class="verticalline"></span> -->
                <!-- <span class="caption">Expiry</span> -->
                <!-- <span class="verticalline"></span> -->
                <!-- <v-divider vertical></v-divider> -->
                <span class="caption">ATM IV<span class="ml-2">0.05%</span></span>
                <!-- <span class="verticalline"></span> -->
                <v-divider vertical></v-divider>
                <span class="caption">IV Chart</span>
                <!-- <span class="verticalline"></span> -->
                <v-divider vertical></v-divider>
                <span class="caption">Analyze OI</span>
                </v-col>
                <v-col></v-col>
            </v-row>
             </div>
          </v-card>
    </v-col>
</v-row>

   <v-simple-table class="mx-2 analysis-table f-semibold a-box">
      <thead>
        <tr>
          <th class="text-center">Time</th>
          <th class="text-right">Open Interest</th>
          <th class="text-right">OI Change</th>
          <th class="text-right">Day's High</th>
           <th class="text-center">OI Interpretation</th>
          <th class="text-right">Day's Low</th>
          <th class="text-right">LTP</th>
          <th class="text-right">Volume</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(item, index) in oiAnalysisTableData" :key="index">
          <td>{{ item.time }}</td>
          <td class="text-right">{{ item.callOi }}</td>
          <td class="text-right"> {{ item.callOi }}
            <div class="py-1 fsize12"><span class="ml-1" :class="parseFloat(item.callOiPer) > 0 ? 'negativevalue' : 'positivevalue'">({{item.callOiPer}}%)</span></div>
          </td>
          <td class="text-right">{{ item.dayHigh }}</td>
           <td>
            <button
              class="white--text font-weight-bold px-2 rounded" :class="item.callInterpretation == 'Long Build Up' ? 'btnGreenClr' : item.callInterpretation == 'Short Build Up' ? 'btnRedClr' : item.callInterpretation == 'Long Unwinding' ? 'btnOrangeClr' : item.callInterpretation == 'Short Covering' ? 'btnBlueClr' : ''">
              {{ item.callInterpretation }}
              <!-- <span class="arrow">{{item.callInterpretation == "Long Build Up" ? "&#8593;" : item.callInterpretation == "Short Build Up" ? "&#8595;" : item.callInterpretation == "Long Unwinding" ? "&#8593;" : item.callInterpretation == "Short Covering" ? "&#8593;": "" }}</span> -->
            </button>
          </td>
          <td class="text-right">{{ item.dayLow }}</td>
          <td class="text-right py-2">{{ item.callLtp }}
            <div class="py-1 fsize12"><span :class="parseFloat(item.callLtpChange) > 0 ? 'negativevalue' : 'positivevalue'">{{item.callLtpChange}}</span> <span class="ml-1" :class="parseFloat(item.callLtpPer) > 0 ? 'negativevalue' : 'positivevalue'">({{item.callLtpPer}}%)</span></div>
          </td>
          <td class="text-right">{{item.volume}}</td>
        </tr>
      </tbody>
    </v-simple-table>
</div>
</template>

<script>
import { mapState} from "vuex"
export default {
  data:()=> ({
    instrument:'BANKNIFTY',
    expirydate:'18NOV21',
    oiAnalysisTableData: [
        { time: "12:40-12:45", callOi: "10,74,500",callOiChange: "35.98", callOiPer: "-14.09", callLtpChange: "40.08", callLtpPer: "12.08",dayHigh: "10,74,500", dayLow: "10,74,500", callLtp: "281.95", callInterpretation: "Long Build Up", volume: "10000", putInterpretation: "Short Build Up", putLtp: "281.95", putBreak: "", putOi: "10,74,500", putOiChange: "35.98", putOiPer: "-14.09",putLtpChange: "40.08",putLtpPer: "12.08"},
        { time: "12:40-12:45", callOi: "10,74,500",callOiChange: "-35.98", callOiPer: "14.09", callLtpChange: "-40.08", callLtpPer: "12.08",dayHigh: "10,74,500", dayLow: "10,74,500", callLtp: "281.95", callInterpretation: "Long Build Up", volume: "1000", putInterpretation: "Short Build Up", putLtp: "281.95", putBreak: "", putOi: "10,74,500", putOiChange: "35.98", putOiPer: "-14.09",putLtpChange: "40.08",putLtpPer: "-12.08"},
        { time: "12:40-12:45", callOi: "10,74,500",callOiChange: "35.98", callOiPer: "14.09", callLtpChange: "40.08", callLtpPer: "-12.08",dayHigh: "10,74,500", dayLow: "10,74,500", callLtp: "281.95", callInterpretation: "Short Covering", volume: "3000", putInterpretation: "Short Build Up", putLtp: "281.95", putBreak: "", putOi: "10,74,500", putOiChange: "35.98", putOiPer: "-14.09",putLtpChange: "40.08",putLtpPer: "12.08"},
        { time: "12:40-12:45", callOi: "10,74,500",callOiChange: "35.98", callOiPer: "-14.09", callLtpChange: "40.08", callLtpPer: "12.08",dayHigh: "10,74,500", dayLow: "10,74,500", callLtp: "281.95", callInterpretation: "Long Build Up", volume: "67000", putInterpretation: "Long Unwinding", putLtp: "281.95", putBreak: "", putOi: "10,74,500", putOiChange: "35.98", putOiPer: "-14.09",putLtpChange: "40.08",putLtpPer: "12.08"},
      ],
  }),

  computed:{
     ...mapState('symbols',['symbolsList','expiryList','strickPriceList','currentStrikePrice','optionList']),
  },

  methods: {
    async initialFunctions(){
      await this.$store.dispatch('symbols/getSymbolsList');
      await this.$store.dispatch('symbols/getSymbolsDetails', this.instrument);
      await this.$store.dispatch('symbols/getCurrentMarketPrice', this.instrument);
      this.$store.commit('symbols/setStrickPrice',await { expiry:this.expirydate , instrument:this.instrument})
      // this.strikeprice = await this.getCurrentPrice(this.currentStrikePrice)
    }
  },

  created() {
    this.initialFunctions()
  }
}
</script>

<style>

</style>